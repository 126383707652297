<template>
  <v-container fluid>
    <!-- <div class="d-flex justify-center mb-6">
      <img src="../assets/login.png" height="50" />
    </div> -->

    <v-row class="pa-md-4 mb-12" justify="center">
      <h1>Nous sécurisons votre connexion</h1>
    </v-row>

    <v-row class="pa-md-4 mb-12" justify="center">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <v-row class="pa-md-4 mb-12" justify="center">
      <v-col class="pa-md-4 mb-12" justify="center">
        <span
          style="color: red"
          v-if="!loading"
          class="text-h5 d-flex justify-center"
        >
          {{ errorText_1 }}
        </span>

        <span v-if="!loading" class="text-h5 d-flex justify-center">
          {{ errorText_2 }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as loginService from "@/service/login_service.js";
import * as roleService from "@/service/role_service.js";
import * as ssoService from "@/service/sso_service.js";
import * as loginStoreService from "@/service/login_store_service.js";

import { defines as routes } from "@/router/defines.js";

import * as logger from "@/tools/logger.js";

export default {
  name: "LoadingConnection",
  components: {},
  mixins: [],

  data() {
    return {
      /** Chargement de l'authent */
      loading: true,

      /** Les textes d'erreur */
      errorText_1: "",
      errorText_2: "",
    };
  },

  methods: {
    /** Chargement de la vue, attends que la réponse de l'iframe soit traitée */
    async load() {
      // Timeout pour la boucle de fin de boucle si l'utilisateur est trop long à venir
      let timeout = 20;
      // Vérification de la réponse de la connection
      let interval = setInterval(() => {
        // Lorsque la réponse de l'iframe est traitée, on passe au reste de la connexion.
        if (!ssoService.getAuthLoading) {
          clearInterval(interval);
          this.connexion();
        }
        if (timeout == 0) {
          clearInterval(interval);
          this.connexionOut();
        }
        timeout--;
      }, 500);
    },

    /** Méthode de management des étapes de la connexion à l'app */
    async connexion() {
      try {
        // Récupération pour savoir si l'utilisateur est connecté au sso
        let isConnectedSso =
          loginStoreService.getUserConnectedSso() == "true" ? true : false;

        logger.error("User connected sso  " + isConnectedSso);

        if (isConnectedSso) {
          // Vérif expiration token et refresh si nécessaire
          await loginService.getExpirationAndReniewTokenIp();

          // Demande d'obtention du token dgauth
          await loginService.connectDGauth(this.$api.getDgAuthApi());

          // Demande de génération des rôles
          roleService.setRole();

          // La connexion as pu se faire sans problème, on met à jour toutes les données de fin de connexion.
          loginService.endConnection();

          // Récupération de la route stockée avant demande login
          let route = loginStoreService.getExpectedRoute();
          if (route) {
            this.$router.replace(route);
          } else {
            this.$router.replace(route.home.path);
          }
        } else {
          this.connexionOut();
        }
      } catch (error) {
        this.connexionOut(error);
      }
    },

    /** Affiche les messages d'erreur et redirige vers l'écran de login */
    connexionOut(error = null) {
      this.loading = false;
      logger.error("Erreur de connexion --> " + JSON.stringify(error, null, 4));
      // affichage message d'erreur
      this.errorText_1 = "Impossible de vous authentifier.";
      this.errorText_2 = "Vous allez être redirigé sur la page de connexion.";

      // Déconnection de l'utilisateur
      loginService.disconnectUser();

      // Pose un temps d'attente pour que l'utilisateur puisse voir le message d'erreur
      setTimeout(() => {
        this.$router.replace(routes.connexion.path);
      }, 3000);
    },
  },
  computed: {},

  mounted() {
    // Attente pour laisser le temps à l'iframe de se charger
    setTimeout(() => {
      // Au bout du temps indiqué en param, on demande la connexion à l'iframe puis on charge la vue
      loginService.getConnexion();
      this.load();
    }, 1000);
  },
};
</script>
